import Vue from 'vue';
import VueRouter from 'vue-router';

// Route level code-splitting
// This generates a separate chunk for this route
// which is lazy-loaded when the route is visited.
const Report = () => import('@/views/Report.vue');
const Login = () => import('@/views/Login.vue');
const NoAccess = () => import('@/views/NoAccess.vue');
const Settings = () => import('@/views/Settings.vue');
const SignUp = () => import('@/views/SignUp.vue');

import { checkAuth } from '@/auth';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		redirect: '/login',
	},
	{
		path: '/login',
		name: 'Login',
		component: Login,
	},
	{
		path: '/no-access',
		name: 'NoAccess',
		component: NoAccess,
	},
	{
		path: '/signup',
		name: 'SignUp',
		component: SignUp,
	},
	{
		path: '/report',
		name: 'Report',
		component: Report,
		beforeEnter: checkAuth,
	},
	{
		path: '/settings',
		name: 'Settings',
		component: Settings,
		beforeEnter: checkAuth,
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
