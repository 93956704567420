<template>
	<div id="app">
		<nav-bar :hideNavbar="$route.query.hideNavbar"></nav-bar>
		<router-view class="container" />
	</div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar';

export default {
	components: { NavBar },
};
</script>

<style lang="scss">
// Import Bulma's core
@import '~bulma/sass/utilities/_all';

$family-sans-serif: 'Lato', sans-serif;

// Set your colors
$primary: #50c19e;
$primary-light: findLightColor($primary);
$primary-dark: findDarkColor($primary);
$primary-invert: findColorInvert($primary);

/*$info: #4daad7;
$info-light: findLightColor($info);
$info-dark: findDarkColor($info);
$info-invert: findColorInvert($info);*/

/*$success: #bec531;
$success-light: findLightColor($success);
$success-dark: findDarkColor($success);
$success-invert: findColorInvert($success);*/

$warning: #f28932;
$warning-light: findLightColor($warning);
$warning-dark: findDarkColor($warning);
$warning-invert: findColorInvert($warning);

/*$danger: #ee503f;
$danger-light: findLightColor($danger);
$danger-dark: findDarkColor($danger);
$danger-invert: findColorInvert($danger);*/

// Lists and maps
$custom-colors: null !default;
$custom-shades: null !default;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: mergeColorMaps(
	(
		'white': (
			$white,
			$black,
		),
		'black': (
			$black,
			$white,
		),
		'light': (
			$light,
			$light-invert,
		),
		'dark': (
			$dark,
			$dark-invert,
		),
		'primary': (
			$primary,
			$primary-invert,
			$primary-light,
			$primary-dark,
		),
		'link': (
			$link,
			$link-invert,
			$link-light,
			$link-dark,
		),
		'info': (
			$info,
			$info-invert,
			$info-light,
			$info-dark,
		),
		'success': (
			$success,
			$success-invert,
			$success-light,
			$success-dark,
		),
		'warning': (
			$warning,
			$warning-invert,
			$warning-light,
			$warning-dark,
		),
		'danger': (
			$danger,
			$danger-invert,
			$danger-light,
			$danger-dark,
		),
	),
	$custom-colors
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import '~bulma';
@import '~buefy/src/scss/buefy';

body,
button,
input,
optgroup,
select,
textarea {
	font-family: 'Lato', sans-serif;
}

.is-no-wrap {
	white-space: nowrap;
}

@media print {
	@page {
		size: A3;
		margin: 15px;
	}

	.print-button,
	nav {
		display: none;
	}

	.page-beak {
		page-break-after: always;
	}

	.apexcharts-legend-marker {
		-webkit-print-color-adjust: exact;
	}
}
</style>
