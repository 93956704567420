import parsePhoneNumber from 'libphonenumber-js';
import { translate } from 'vue-gettext';
const { gettext: $gettext } = translate;

function checkPasswordComplexity(password) {
	// Regex to check that
	// The password length must be greater than or equal to 8      (?=^.{8,}$)
	// The password must contain one or more uppercase characters  (?=.*[A-Z])
	// The password must contain one or more lowercase characters  (?=.*[a-z])
	// The password must contain one or more numeric values        (?=.*[0-9])
	// The password must contain one or more special characters    (?=.*[!@#$%^&*(),.?":{}|<>~`\'/=+ \\\\\\- etc etc])
	const normalWeird = '!@#$%^&*(),.?":{}|<>~`/=+_';
	const slashWeird = "'\\-\\[\\]\\\\";
	const reallyWeird = '¡™£¢∞§¶•ªº“‘æ≤≥π¥ƒ©ç®¬÷≠«åø´¨ˆ∂˙†˜ß–…œ∆˚≈∫µ∑√Ω`⁄€‹›ﬁﬂ‡°·‚”’Æ¯˘∏ÁÏ˝Ç‰Ò¿±»ÅØ´¨ˆÎÓˇ˜Í—ÚŒÔ˛ıÂ„◊¸';

	const regex = RegExp(`(?=^.{8,}$)(?=.*[0-9])(?=.*[${normalWeird}${slashWeird}${reallyWeird}])(?=.*[A-Z])(?=.*[a-z]).*$`);

	if (!regex.test(password)) {
		const passwordComplexityErrors = [];
		if (!RegExp('(?=^.{8,}$)').test(password)) passwordComplexityErrors.push($gettext('The password must have at least 8 characters'));
		if (!RegExp('(?=.*[A-Z])').test(password)) passwordComplexityErrors.push($gettext('The password must have at least 1 uppercase character'));
		if (!RegExp('(?=.*[a-z])').test(password)) passwordComplexityErrors.push($gettext('The password must have at least 1 lowercase character'));
		if (!RegExp('(?=.*[0-9])').test(password)) passwordComplexityErrors.push($gettext('The password must have at least 1 numeric character'));
		if (!RegExp(`(?=.*[${normalWeird}${slashWeird}${reallyWeird}])`).test(password)) passwordComplexityErrors.push($gettext('The password must have at least 1 special character'));
		throw Error(passwordComplexityErrors.join('|'));
	}

	return true;
}

function isValidPhoneNumber(phoneNumber) {
	if (!phoneNumber || phoneNumber.length < 6) return false;
	const phone = parsePhoneNumber(`${phoneNumber}`, 'BE');
	return phone.isValid();
}

// Check the api and add subdomain if needed
function correctDomain(clientHostname, gatewayUrl) {
	if (!clientHostname || !gatewayUrl) return;

	// Check the VUE_APP_GATEWAY url
	const gwUrl = new URL(gatewayUrl);
	const gwHostname = gwUrl.hostname;

	// localhost, nothing to change
	if (gwHostname === 'localhost') return gatewayUrl;

	// check for sub domains
	const hostNameParts = clientHostname.split('.');
	const hostNameLength = hostNameParts.length;
	const gwParts = gwHostname.split('.');
	const urlLength = gwParts.length;

	//Not running on subdomain, nothing to change
	if (hostNameLength <= 3 && urlLength <= 3) return gatewayUrl;

	//Get the subdomain parts
	const subDomainParts = hostNameParts.splice(0, hostNameLength - 3);

	//Get the gateway parts
	const gatewayParts = gwParts.splice(urlLength - 3, urlLength);

	// Construct gw url parts
	const urlParts = [...subDomainParts, ...gatewayParts];

	// Update the gw url with new clientHostname
	gwUrl.hostname = urlParts.join('.');

	let url = gwUrl.href;

	return url.endsWith('/') ? url.slice(0, -1) : url;
}

export { checkPasswordComplexity, isValidPhoneNumber, correctDomain };
