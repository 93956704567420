import Vue from 'vue';
import GetTextPlugin from 'vue-gettext';
import translations from '@/assets/translations.json';
import detectBrowserLanguage from 'detect-browser-language';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';

const locale = detectBrowserLanguage() || 'en';

dayjs.locale(locale);

import { Button, Checkbox, Dropdown, Datepicker, Dialog, Field, Input, Select, Icon, Loading, Message, Navbar, Notification, Pagination, Radio, Steps, Switch, Skeleton, Table, Toast, Tooltip } from 'buefy';
import VueApexCharts from 'vue-apexcharts';

Vue.use(GetTextPlugin, {
	availableLanguages: {
		en: 'English',
		nl: 'Nederlands',
	},
	defaultLanguage: locale,
	translations,
});

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.use(Button);
Vue.use(Checkbox);
Vue.use(Dropdown);
Vue.use(Datepicker);
Vue.use(Dialog);
Vue.use(Field);
Vue.use(Input);
Vue.use(Select);
Vue.use(Icon);
Vue.use(Loading);
Vue.use(Message);
Vue.use(Navbar);
Vue.use(Notification);
Vue.use(Pagination);
Vue.use(Radio);
Vue.use(Steps);
Vue.use(Switch);
Vue.use(Skeleton);
Vue.use(Table);
Vue.use(Toast);
Vue.use(Tooltip);
Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app');

/**
 * Vue filter to round the decimal to the given place.
 * http://jsfiddle.net/bryan_k/3ova17y9/
 *
 * @param {String} value    The value string.
 * @param {Number} decimals The number of decimal places.
 */
Vue.filter('round', function(value, decimals) {
	if (!value) {
		value = 0;
	}

	if (!decimals) {
		decimals = 2;
	}

	value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
	return value;
});

// var connection = new WebSocket('ws://localhost:8080');

// // When the connection is open, send some data to the server
// connection.onopen = function() {
// 	connection.send('message', 'hello there websocket');
// };

// // Log errors
// connection.onerror = function(error) {
// 	console.log('WebSocket Error ' + error);
// };

// // Log messages from the server
// connection.onmessage = function(e) {
// 	console.log('Server: ' + e.data);
// };
