import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { v4 as uuidv4 } from 'uuid';
import shajs from 'sha.js';
import jwt_decode from 'jwt-decode';

import api from './api.js';
import router from '../router';
import { correctDomain } from '../utils';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
	storage: window.localStorage,
	key: process.env.VUE_APP_CLIENT_ID,
});

const clientId = process.env.VUE_APP_CLIENT_ID;
const redirectUri = `${window.location.origin}/login`;
const vueAppAuthServiceUrl = process.env.VUE_APP_AUTH_SERVICE || 'http://localhost:4910';

const authService = correctDomain(window.location.hostname, vueAppAuthServiceUrl);

export default new Vuex.Store({
	state: {
		version: process.env.PACKAGE_VERSION || '0',
		perPage: 20,
		user: undefined,
		access: undefined,
		tokens: undefined,
		authorization: undefined,
	},
	mutations: {
		setPerPage(state, value) {
			state.perPage = value;
		},
		setUser(state, value) {
			Vue.set(state, 'user', value);
		},
		setAccess(state, value) {
			Vue.set(state, 'access', value);
		},
		setTokens(state, value) {
			Vue.set(state, 'tokens', value);
		},
		setAuthorization(state, value) {
			Vue.set(state, 'authorization', value);
		},
	},
	actions: {
		// LOGIN, LOGOUT, AUTHORIZATION, TOKENS
		logout({ commit }, redirectTo) {
			commit('setUser');
			commit('setTokens');
			commit('setAuthorization');
			router.push({ name: 'Login', query: { redirectTo } });
		},

		authorize({ commit }, redirectTo) {
			const codeVerifier = uuidv4();
			const authorizeData = {
				codeChallenge: shajs('sha256')
					.update(codeVerifier)
					.digest('hex'),
				state: uuidv4(),
			};
			const params = {
				responseType: 'code',
				codeChallengeMethod: 'S256',
				clientId,
				redirectUri,
				...authorizeData,
			};
			commit('setAuthorization', {
				codeVerifier,
				...authorizeData,
				redirectTo,
			});
			const url = new URL(`${authService}/authorize`);
			return api.get(url.toString(), params, { skipAuthRefresh: true });
		},

		async getMeteorTokens({ dispatch, commit }, code) {
			try {
				const url = new URL(`${authService}/oauth/token`);
				const params = new URLSearchParams({
					grantType: 'meteor_token',
					meteorToken: code,
					clientId,
					redirectUri,
				});
				const tokens = await api.post(url.toString(), params, { skipAuthRefresh: true });
				commit('setTokens', tokens);
				dispatch('setUserFromToken');
				dispatch('setAccesssFromToken');
				return tokens;
			} catch (err) {
				return Promise.reject(err);
			}
		},

		async getTokens({ state, dispatch, commit }, code) {
			try {
				const url = new URL(`${authService}/oauth/token`);
				const params = new URLSearchParams({
					grantType: 'authorization_code',
					codeVerifier: state.authorization.codeVerifier,
					code,
					clientId,
					redirectUri,
				});
				const tokens = await api.post(url.toString(), params, { skipAuthRefresh: true });
				commit('setTokens', tokens);
				dispatch('setUserFromToken');
				dispatch('setAccesssFromToken');
				return tokens;
			} catch (err) {
				return Promise.reject(err);
			}
		},

		async getRefreshToken({ state, commit }) {
			try {
				const url = new URL(`${authService}/oauth/token`);
				const params = new URLSearchParams({
					grantType: 'refresh_token',
					refreshToken: state.tokens.refreshToken,
					clientId,
				});
				const tokens = await api.post(url.toString(), params);
				commit('setTokens', tokens);
				return tokens;
			} catch (err) {
				return Promise.reject(err);
			}
		},

		async setUserFromToken({ commit, state }) {
			try {
				const user = jwt_decode(state?.tokens?.idToken);
				if (!user) throw new Error('Could not decode idToken');
				commit('setUser', user);
				return user;
			} catch (err) {
				return Promise.reject(err);
			}
		},

		async setAccesssFromToken({ commit, state }) {
			try {
				const { access } = jwt_decode(state?.tokens?.accessToken);
				if (!access) throw new Error('Could not decode accessToken');
				commit('setAccess', access);
				return access;
			} catch (err) {
				return Promise.reject(err);
			}
		},

		// PERSIST PARTS OF THE APP STATE

		// Rows per page in a paginated table
		setPerPage({ commit }, value) {
			commit('setPerPage', value);
		},

		// APP-SPECIFIC CALLS TO THE GATEWAY
		// Here are some examples...

		patchUser({ commit }, { id, ...params }) {
			return api.patch(`users/${id}`, params).then(result => {
				if (result.value) commit('setUser', result.value);
				return result;
			});
		},

		getOnePagerReport(_, params) {
			return api.get(`onepager/${params.patientId}/${params.coupleId}`);
		},

		getOnePagerReportPDF(_, params) {
			let languageParameter;
			if (params?.language) {
				languageParameter = { language: params.language };
			}
			return api.get(`onepager/${params.patientId}/${params.coupleId}/pdf`, languageParameter, {
				responseType: 'arraybuffer',
				headers: {
					Accept: 'application/pdf',
				},
			});
		},

		getHospitals(_, params) {
			if (!params) params = { sortBy: 'name.asc' };
			return api.get('hospitals', params);
		},

		getUserRoles(_, params) {
			if (!params) params = { sortBy: 'group.asc,name.asc' };
			return api.get('userroles', params);
		},
	},
	modules: {},
	plugins: [vuexLocal.plugin],
});
