import store from '@/store';

// TODO: this should check if the token is valid too

function checkAuth(to, from, next) {
	const isLoggedIn = store.state.user ? store.state.user._id : undefined;
	//Is there a meteor token provided?
	if (to.query?.meteorToken) {
		//if meteor token redirect to login page to exchange the meteor token for auth token
		let meteorToken = to.query.meteorToken;
		delete to.query.meteorToken;
		return next({ name: 'Login', query: { meteorToken, redirectTo: { path: to.path, query: to.query } } });
	} else {
		//No meteor token
		if (isLoggedIn) {
			//If already logged in continue
			return next();
		} else {
			//If not logged in continue to login page
			return next({ name: 'Login', query: { redirectTo: { path: to.path, query: to.query } } });
		}
	}
}

export { checkAuth };
