import axios from 'axios';
import qs from 'qs';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import store from './index';
import router from '../router';
import { correctDomain } from '../utils';

const vueAppGatewayUrl = process.env.VUE_APP_GATEWAY || 'http://localhost:4911';

const api = correctDomain(window.location.hostname, vueAppGatewayUrl);

createAuthRefreshInterceptor(axios, refreshAuthLogic, {
	statusCodes: [401],
	pauseInstanceWhileRefreshing: true,
	// onRetry: config => {
	// 	console.log('config:', config);
	// },
});

function refreshAuthLogic(failedRequest) {
	return new Promise((resolve, reject) => {
		return store
			.dispatch('getRefreshToken')
			.then(() => {
				failedRequest.response.config.headers.authorization = `Bearer ${store.state.tokens?.accessToken}`;
				resolve();
			})
			.catch(err => {
				console.error('refreshAuthLogic err:', err);
				if (err.name === 'NotFound') {
					// RefreshToken not found
					// User has to log in again
					// Give the currentRoute as parameter to the logout action to redirect to after login
					resolve(store.dispatch('logout', router.currentRoute?.fullPath));
				} else {
					reject(err);
				}
			});
	});
}

// Request interceptor to add authorization header when a token is available
axios.interceptors.request.use(
	request => {
		// Add authorization hearder if a token is found in the store
		if (store?.state?.tokens?.accessToken) request.headers.authorization = `Bearer ${store.state.tokens.accessToken}`;
		request.headers['Accept-version'] = 'v2';
		return request;
	},
	function(err) {
		console.error('interceptors.request err:', err);
		return Promise.reject(err);
	},
);

// Response interceptor to catch 30x redirects and unify error handling
axios.interceptors.response.use(
	res => {
		// The code can’t just check response.status: In the case of redirects, that will be the
		// status code of whatever response ultimately comes back as the result of any redirects.
		// So we need to detect redirect by comparing request and reponse urls.
		if (res.status === 200 && res.config.url !== res.request.responseURL) {
			window.location.assign(res.request.responseURL);
		}
		return res.data ?? res;
	},
	err => {
		const errData = err?.response?.data;
		if (errData) {
			// The server gave a reply
			const newErr = new Error();
			if (errData.name && errData.code && errData.codeName) {
				// It's a mongodb error
				newErr.name = `${errData.name} ${errData.code}`;
				newErr.message = errData.codeName;
			} else if (errData.message && errData.code) {
				// It's an expected error
				newErr.name = errData.code;
				newErr.message = errData.message;
			} else if (err.response) {
				// It's an unexpected error
				newErr.name = `Error ${err.response.status}`;
				newErr.message = err.response.statusText;
			}
			console.error('response interceptor newErr:', newErr);
			return Promise.reject(newErr);
		}
		console.error('response interceptor err:', err);
		return Promise.reject(err);
	},
);

function get(route, params, axiosParams) {
	return new Promise((resolve, reject) => {
		try {
			const url = route.substring(0, 4) === 'http' ? new URL(route) : new URL(`${api}/${route}`);
			if (params) {
				if (typeof params === 'string') url.pathname = `${route}/${params}`;
				else if (typeof params === 'object') {
					url.search = qs.stringify(params);
				}
			}
			axios
				.get(url.toString(), { withCredentials: true, ...axiosParams })
				.then(resolve)
				.catch(reject);
		} catch (err) {
			reject(err);
		}
	});
}

function post(route, params, axiosParams) {
	return new Promise((resolve, reject) => {
		try {
			const url = route.substring(0, 4) === 'http' ? new URL(route) : new URL(`${api}/${route}`);
			axios
				.post(url.toString(), params, { withCredentials: true, ...axiosParams })
				.then(resolve)
				.catch(reject);
		} catch (err) {
			reject(err);
		}
	});
}

function patch(route, params, axiosParams) {
	return new Promise((resolve, reject) => {
		try {
			const url = route.substring(0, 4) === 'http' ? new URL(route) : new URL(`${api}/${route}`);
			axios
				.patch(url.toString(), params, { withCredentials: true, ...axiosParams })
				.then(resolve)
				.catch(reject);
		} catch (err) {
			reject(err);
		}
	});
}

function put(route, params, axiosParams) {
	return new Promise((resolve, reject) => {
		try {
			const url = route.substring(0, 4) === 'http' ? new URL(route) : new URL(`${api}/${route}`);
			axios
				.put(url.toString(), params, { withCredentials: true, ...axiosParams })
				.then(resolve)
				.catch(reject);
		} catch (err) {
			reject(err);
		}
	});
}
function del(route, params, axiosParams) {
	return new Promise((resolve, reject) => {
		try {
			const url = route.substring(0, 4) === 'http' ? new URL(route) : new URL(`${api}/${route}`);
			// Need this different format, otherwise the cookie is not sent
			// https://github.com/axios/axios/issues/509#issuecomment-370761888
			axios({
				url: url.toString(),
				method: 'delete',
				data: params,
				withCredentials: true,
				...axiosParams,
			})
				.then(resolve)
				.catch(reject);
		} catch (err) {
			reject(err);
		}
	});
}

export default {
	get,
	post,
	del,
	patch,
	put,
};
