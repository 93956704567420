<template>
	<b-navbar spaced wrapper-class="container" v-if="!hideNavbar">
		<template slot="brand">
			<b-navbar-item tag="router-link" :to="{ path: '/report' }">
				<img src="../assets/moveup-logo.png" alt="moveUP" />
			</b-navbar-item>
		</template>
		<template slot="start" v-if="user && user._id">
			<!--			<b-navbar-item tag="router-link" :to="{ path: '/dashboard' }" v-translate>
				Dashboard
			</b-navbar-item>-->
		</template>

		<template slot="end">
			<!--			<b-navbar-item href="https://support.orthopedics.moveup.care/" target="_blank" rel="noopener" v-translate>
				Support
			</b-navbar-item>-->
			<b-navbar-dropdown :label="$gettext('Account')" v-if="user && user._id">
				<!--				<b-navbar-item tag="router-link" :to="{ path: '/settings' }" v-translate>
					Settings
				</b-navbar-item>-->
				<b-navbar-item @click="doLogout" v-translate>
					Log out
				</b-navbar-item>
			</b-navbar-dropdown>
		</template>
	</b-navbar>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
	props: ['hideNavbar'],
	computed: {
		...mapState(['user']),
	},
	methods: {
		...mapActions(['logout']),
		doLogout() {
			this.logout();
			this.$router.push('/login');
		},
	},
};
</script>
